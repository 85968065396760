import React, {useState} from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios'

import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

import styles from '../../styles/register-modal.module.css';

import separator from '../../assets/or-separator.svg';

const Content = ({show, clickLogin, onHide, questions}) => {
    const [formBody, setformBody] = useState({});

    const handleFormChange = (e) => {
        const updatedFormBody = {[e.target.name] : e.target.value}  
        setformBody( formBody => ({
            ...formBody,
            ...updatedFormBody,
        }));
    }

    const [errMessage, setErrMessage] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if(questions != {}) {
            let updatedFormBody = {['questioner'] : questions}  
            setformBody( formBody => ({
                ...formBody,
                ...updatedFormBody,
            }));
        }


        axios.post('https://api.bioaqua.co.id/api/auth/registration', formBody)
        .then(response => {
            sessionStorage.setItem('token_expired', response.data.expired_at);
            sessionStorage.setItem('token', response.data.token);
            
            if(response.status == 206) {
                window.location = '/questioner'
                return
            }

            window.location = '/registration/confirmation'
            return
        })
        .catch(errors => {
            console.log(errors);
            setErrMessage(errors.response.data.message)
        })
    }

    const responseGoogleSuccess = (response) => {
        var formBody = {
            email: response.email,
            name: response.name,
            googleId: response.sub,
            flag: 'google'
        }

        if(questions != {}) {
            formBody.questioner = questions
        }

        axios.post('https://api.bioaqua.co.id/api/auth/google', formBody)
        .then(response => {
            sessionStorage.setItem('token_expired', response.data.expired_at);
            sessionStorage.setItem('token', response.data.token);

            if(response.status == 206) {
                window.location = '/questioner'
                return
            } else if(response.status == 202) {
                window.location = '/registration/confirmation'
                return
            }

            window.setTimeout(function() {
                window.location.reload();
            }, 1000);
        })
        .catch(errors => {
            console.log(errors);
            setErrMessage(errors.response.data.message)
        })
    }

    return (
        <>
        <Modal show={show} onHide={onHide} className={styles.main}>
            <Modal.Header closeButton className={styles.header}>
                <Modal.Title className={styles.title}>Register</Modal.Title><br/>
            </Modal.Header>
            <div className={styles.subtitlesection}>
                <p className={styles.subtitle}>Already have an account? <a className={styles.signinlink} onClick={clickLogin}>Sign In</a></p>
            </div>
            <Modal.Body className={styles.body}>
                <Form className={styles.form} onSubmit={handleFormSubmit}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className={styles.formlabel}>First Name</Form.Label>
                                <Form.Control name="first_name" type="text" placeholder="Enter First Name" onChange={handleFormChange}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className={styles.formlabel}>Last Name</Form.Label>
                                <Form.Control name="last_name" type="text" placeholder="Enter Last Name" onChange={handleFormChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className={styles.formlabel}>Email</Form.Label>
                        <Form.Control name="email" type="email" placeholder="Enter email" onChange={handleFormChange} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className={styles.formlabel}>Password</Form.Label>
                        <Form.Control name="password" type="password" placeholder="Password" onChange={handleFormChange} />
                    </Form.Group>
                    <p className={styles.errormessage}>{errMessage}</p>
                    <Button variant="dark" type="submit" className={styles.submitbtn}>
                        Register
                    </Button>
                </Form>
            
                <img src={separator} className={styles.separator} />
                <center>
                    <GoogleLogin
                    onSuccess={credentialResponse => {
                        var decoded = jwt_decode(credentialResponse.credential);
                        responseGoogleSuccess(decoded);
                    }}
                    onError={() => {
                        console.log('Login Failed');
                        setErrMessage('Gagal Register, Terjadi Kesalahan Server')
                    }}
                    size={'large'}
                    shape={'circle'}
                    width={'400'}
                    />;
                </center>
            </Modal.Body>   
        </Modal>
        </>
    );
};

export default Content;