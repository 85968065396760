import React, { useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';

import styles from '../../styles/articlecard.module.css';

const Content = ({data}) => {
    return (
        <>
        <Container>
                <div className={styles.main}>
                    <a href={'/articles/'+data.title}>
                        <img className={styles.image} src={'https://api.bioaqua.co.id/article/'+data.image} />
                    </a>
                    <div className={styles.content}>
                        <div className={styles.textsection}>
                            <h5 className={styles.title}>{data.title}</h5>
                            <p className={styles.subtitle}>{data.subtitle}</p>
                        </div>
                        <div className={styles.btnsection}>
                            <a href={'/articles/'+encodeURIComponent(data.title)}><Button className={styles.button}>Read More</Button></a>
                        </div>
                    </div>
                </div>
        </Container>
        </>
    );
};

export default Content;