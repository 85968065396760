import React, {useEffect, useState} from 'react'
import WebNavbar from '../../components/navbar'
import HomeBanner from '../../components/home-banner'
import WhatsNew from '../../components/home-whatsnew'
import Social from '../../components/home-social'
import Basties from '../../components/home-basties'
import Footer from '../../components/footer'
import ButtonWa from '../../components/button-wa'
import Article from '../../components/article-home'
import LoadingScreen from '../../components/loading-screen'
import MenuButton from '../../components/home-menu-button'
import HomeAbout from '../../components/home-about'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import BannerBiosquad from '../../assets/join-biosquad-banner.png'
import BannerBiosquadMobile from './../../assets/home-join-us-mobile.png'
import BannerStore from '../../assets/find-us-maps.png'

import { Helmet } from "react-helmet";

const Home = () => {
    const [productsNA, setProductsNA] = useState([{}]);
    const [productsBS, setProductsBS] = useState([{}]);
    const [banner, setBanner] = useState([{}]);
    const [bannerMobile, setBannerMobile] = useState([{}]);

    const[loading, setLoading] = useState(true);

    useEffect(() => {
        const productNA = fetch(
          `https://api.bioaqua.co.id/api/products/category/52a202c1-74ff-4b60-91ff-cc1e55dd1e8a`,
          {
            method: "GET"
          }
        )
        .then(res => res.json())
        .then(response => {
          setProductsNA(response);
        })
        .catch(error => console.log(error));

        const productBS = fetch(
          `https://api.bioaqua.co.id/api/products/category/a8fd28c9-0a26-4363-b8aa-c6a10e0ef142`,
          {
            method: "GET"
          }
        )
        .then(res => res.json())
        .then(response => {
          setProductsBS(response);
        })
        .catch(error => console.log(error));

        const banner = fetch(
          `https://api.bioaqua.co.id/api/banner/home`,
          {
            method: "GET"
          }
        )
        .then(res => res.json())
        .then(response => {
          let banners = []
          let bannersMobile = []
          response.map((value) => {
            if(value.device_type == 2) {
              let bannerSrc = {
                src: 'https://api.bioaqua.co.id/banner/'+value.image,
                  link: value.link
              }
              bannersMobile.push(bannerSrc)
            } else {
              let bannerSrc = {
                  src: 'https://api.bioaqua.co.id/banner/'+value.image,
                  link: value.link
              }
              banners.push(bannerSrc)
            }
          })
          setBanner(banners);
          setBannerMobile(bannersMobile);
        })
        .catch(error => console.log(error));

        const allData = Promise.all([productNA, productBS, banner]);
        allData.then((res) => setLoading(false));
    }, [])

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Home</title>
            <link rel="canonical" href="https://bioaqua.co.id" />
            <meta name="description" content="Welcome to the home page of Bioaqua, your one-stop-shop for high-quality skincare products. Explore our range of products and discover how we can help you achieve healthy and radiant skin."/>
        </Helmet>
        {loading === false ? (
          <>
            <div className='home-card-link'>
              <a className='home-card-link-btn' href='#'>
                Indonesia No'1 Sheetmask Brand <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
            <WebNavbar/>
            <HomeBanner banner={banner} bannerMobile={bannerMobile} />
            <MenuButton />
            <HomeAbout />
            <WhatsNew productsNA={productsNA} productsBS={productsBS} />
            <a href='/biosquad'>
              <img className='home-banner-link' src={BannerBiosquad} />
              <img className='home-banner-link-mobile' src={BannerBiosquadMobile} />
            </a>
            <Social />
            <Article />
            <a href='store'>
            <img className='home-banner-link' src={BannerStore} />
            </a>
            <Footer />
            <ButtonWa />
          </>
        ) : (
          <LoadingScreen />
        )}
          
        </>
      );
}

export default Home;