import loadable from '@loadable/component'
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap';
import moment from "moment";
import sanitizeHtml from 'sanitize-html';
import { useLocation } from "react-router-dom";

import IconTwitter from '../../assets/icon-twitter-blue.svg'
import IconInstagram from '../../assets/icon-instagram-blue.svg'
import IconTiktok from '../../assets/icon-tiktok-blue.svg'

import Logo from '../../assets/main-logo-blue.png'

import { Helmet } from "react-helmet";

const Navbar = loadable(() => import('../../components/navbar'))
const Footer = loadable(() => import('../../components/footer'))

const Home = () => {
  const [showRegister, setShowRegister] = useState(false);
  
  const [data, setData] = useState([]);
  const [latest, setLatest] = useState([]);

  const fbIframe = '<iframe class="fb-embed-iframe" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100085924106336&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1632446757225504" width="340" height="130" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  const useScript = (url) => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, [url]);
  };

  const useQuery = () => {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const [search, setSearch] = useState('');

  let query = useQuery();

  useEffect(() => {
    let searchValue = query.get("search");
    setSearch(searchValue);

    fetch(
        `https://api.bioaqua.co.id/api/article/post/query/withfilter?search=`+searchValue,
        {
          method: "GET"
        }
        )
        .then(res => res.json())
        .then(response => {
          setData(response);
        })
        .catch(error => console.log(error));

    fetch(
        `https://api.bioaqua.co.id/api/article/post/query/withfilter?limit=5`,
        {
          method: "GET"
        }
        )
        .then(res => res.json())
        .then(response => {
          setLatest(response);
        })
        .catch(error => console.log(error));
  }, [])

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      let value = e.currentTarget.value
      window.location = '/articles/browse?search='+value;
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BioAqua - Article Browse</title>
        <link rel="canonical" href="https://bioaqua.co.id/articles/browse" />
        <meta name="description" content="Welcome to the Bioaqua articles page where you can find informative articles on skincare, beauty routines, and the latest trends in the industry. Keep up with the latest news and tips on achieving a healthy and glowing complexion with Bioaqua."/>
      </Helmet>
      <Navbar showRegister={showRegister} setShowRegister={setShowRegister} />
      <div className='article-home'>
        <div className='article-home-header'>
          <Container className='article-home-header-container'>
            <Row>
              <Col className='article-home-header-social'>
                <a href='https://www.instagram.com/bioaquabeautyofficial/'>
                  <img src={IconInstagram} />
                </a>
                <a href='http://tiktok.com/@bioaqua%E2%80%A6'>
                  <img src={IconTiktok} />
                </a>
                <a href='https://twitter.com/bioaquaofficial'>
                  <img src={IconTwitter} />
                </a>
              </Col>
              <Col md={7} className='article-home-header-logo'>
                <img src={Logo} />
              </Col>
              <Col className='article-home-header-menu'>
                <Form.Control className='article-home-header-menu-search' type="text" placeholder="Search..." onKeyDown={handleSearch} />
              </Col>
            </Row>
          </Container>
        </div>
        <div className='article-browse-content'>
          <Container>
            <Row>
              <Col md={8}>
                    <p className='article-browse-content-search-title'>Showing result for <b>{search}</b></p>
                    {
                    data.length > 0 ?
                    data.map((article, index) => (
                        <Row className='article-browse-content-list'>
                            <Col md={6}>
                                <img className='article-home-content-multi-img' src={'https://api.bioaqua.co.id/article/'+article.image} />
                            </Col>
                            <Col md={6}>
                                <p className='article-home-content-category'>{article.category}</p>
                                <a className='article-home-content-link' href={'/articles/'+article.title}>
                                    <h3 className='article-home-content-title'>{article.title}</h3>
                                </a>
                                <p className='article-home-content-timestamp'>{moment(article.created_at).format("MMM D, YYYY")}</p>
                                <div className='article-home-content-paragraph' dangerouslySetInnerHTML={{__html: sanitizeHtml(article.content, {allowedTags: [ 'p' ]})}} />
                            </Col>
                        </Row>
                    ))
                    : (
                        <p>There are no article matches. Try different search</p>
                    )
                    }
              </Col>
              <Col>
                <div className='article-browse-content-latest'>
                  <h4 className='article-home-content-latest-title'>Popular Articles</h4>
                  <div className='article-home-content-latest-list'>
                    {
                      latest.length != 0 ?
                        latest.map((article, index) => (
                          <a key={index} href={'/articles/'+article.title} className='article-home-content-latest-link'>
                            <Row className='article-home-content-latest-row'>
                              <Col md={4} xs={4}>
                                <img className='article-home-content-latest-img' src={'https://api.bioaqua.co.id/article/'+article.image} />
                              </Col>
                              <Col md={8} xs={8}>
                                <h5>{article.title}</h5>
                                <p>{moment(article.created_at).format("MMM D YYYY")} &#8729; {article.category}</p>
                              </Col>
                            </Row>
                          </a>   
                        )): (
                        <>
                          <center>
                            <p>There are no articles yet</p>
                          </center>
                        </>
                      )
                    }
                  </div>
                </div>
                <div className='article-home-content-fb-embed'>
                  <h5 className='article-home-content-embed-title'>Like Our Facebook</h5>
                  <div className='article-home-content-fb-embed-content' dangerouslySetInnerHTML={{__html: fbIframe}}></div>
                </div>
                <div className='article-home-content-yt-embed'>
                  <h5 className='article-home-content-embed-title'>Subscribe Our Youtube</h5>
                  <div className='article-home-content-yt-embed-content'>
                    <div>{useScript("https://apis.google.com/js/platform.js")}</div>
                    <div
                      class="g-ytsubscribe"
                      data-channelid="UCBJXJfcvt4qYdfUKBOTwDpw"
                      data-layout="full"
                      data-count="hidden" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
      
    </>
  )
}

export default Home;