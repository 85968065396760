import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { Navbar, Nav, Container, Button, Dropdown, InputGroup, Form } from 'react-bootstrap';
import NavLogo from '../../assets/logo-white.png'

import LoginModal from '../login-modal'
import RegisterModal from '../register-modal'

import userIcon from '../../assets/user-icon.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSearch } from '@fortawesome/free-solid-svg-icons'

const WebNavbar = ({showRegister, setShowRegister, questions}) => {
  const handleCloseRegister = () => setShowRegister(false);
  const handleShowRegister = () => setShowRegister(true);

  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);

  const handleClickLogin = () => {
    setShowRegister(false);
    setShowLogin(true);
  }
  const handleClickSignUp = () => {
    setShowLogin(false);
    setShowRegister(true);
  }

  const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    let token = sessionStorage.getItem('token')
    if(token!=""&&token!=undefined) {
      axios.get('https://api.bioaqua.co.id/api/users/data', {
        headers: {
          Authorization: token
        }
      })
        .then(response => {
            setUserData(response.data)
            setLoggedIn(true)
            return
        })
        .catch(errors => {
            console.log(errors)
            return
        })
    }
    setLoggedIn(false);
  }, [])

  const handleLogout = () => {
    sessionStorage.clear()
    setLoggedIn(false)
    window.location = '/'
  }


    return (
      <>
        <Navbar className='main-navbar' expand="lg" variant='dark'>
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className='navbar-toggle-mobile' />
            <Navbar.Brand href="/" className='navbar-logo'>
                <img className='navbar-logo-img' src={NavLogo} />
            </Navbar.Brand>
            <Dropdown className='navbar-mobile-search' align={'end'}>
              <Dropdown.Toggle variant="info" id="dropdown-basic" className='navbar-mobile-search-toggle'>
                <FontAwesomeIcon icon={faSearch} />
              </Dropdown.Toggle>
              <Dropdown.Menu className='navbar-mobile-search-menu'>
                  <InputGroup className="">
                      <Form.Control
                        name='product'
                        aria-label="Product's Name"
                        aria-describedby="basic-addon2"
                        className='navbar-mobile-search-form'
                      />
                      <Button className='navbar-mobile-search-btn' variant="outline-secondary" id="button-addon2">
                        search
                      </Button>
                  </InputGroup>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className='navbar-mobile-user' align={'end'}>
              <Dropdown.Toggle variant="info" id="dropdown-basic" className='navbar-mobile-user-toggle'>
                <FontAwesomeIcon icon={faUser} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {!loggedIn ? (
                      <Dropdown.Item href="#" onClick={handleShowLogin}>Login/Sign Up</Dropdown.Item>
                  ) : (
                    <>
                      <Dropdown.Item href="/member/ticket">Member Area</Dropdown.Item>
                      <Dropdown.Item href="#" onClick={handleLogout}>Logout</Dropdown.Item>
                    </>
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className='navbar-toggle-desktop' />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link className='main-navbar-link' href="/products">Products</Nav.Link>
                <Nav.Link className='main-navbar-link' href="/articles">BioEduSkin</Nav.Link>
                <Nav.Link className='main-navbar-link' href="/biosquad">BIOAQUA Squad</Nav.Link>
                <Nav.Link className='main-navbar-link' href="/store">Store Location</Nav.Link>
                <Nav.Link className='main-navbar-link' href="/about">About Us</Nav.Link>
                <div className='main-navbar-btn-section'>
                  {
                    !loggedIn ? (
                      <Button variant='light' className='main-navbar-button' onClick={handleShowLogin}>
                          Login/Sign Up
                      </Button>
                    ) : (
                      <>
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic" className='main-navbar-loggedin-btn'>
                            Hi, {userData.name ? userData.name.substring(0, userData.name.indexOf(' ')) : ''}
                            <span className='ms-2'><img className='main-navbar-loggedin-btn-icon' src={userIcon} /></span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {
                              userData.verified_level == 2 ? (
                                <Dropdown.Item href="/member/ticket">Member Area</Dropdown.Item>
                              ) : (
                                <></>
                              )
                            }
                            <Dropdown.Item href="#" onClick={handleLogout}>Logout</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    )
                  }
                </div>
                <Dropdown className='navbar-search' align={'end'}>
                  <Dropdown.Toggle variant="info" id="dropdown-basic" className='navbar-search-toggle'>
                    <FontAwesomeIcon icon={faSearch} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='navbar-search-menu'>
                    <InputGroup className="">
                          <Form.Control
                            name='product'
                            aria-label="Product's Name"
                            aria-describedby="basic-addon2"
                            className='navbar-search-form'
                          />
                          <Button className='navbar-search-btn' variant="outline-secondary" id="button-addon2">
                            search
                          </Button>
                      </InputGroup>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <LoginModal show={showLogin} onHide={handleCloseLogin} clickSignUp={handleClickSignUp} />
        <RegisterModal questions={questions} show={showRegister} onHide={handleCloseRegister} clickLogin={handleClickLogin}/>
      </>
    )
}

export default WebNavbar;