import React from 'react';

import WaBtn  from '../../assets/whatsapp-icon.svg'

const Content = () => {
    return (
        <>
        <div className='button-wa'>
            <a href='https://api.whatsapp.com/send?phone=6281223410002&text=Halo%20MinBio,%20aku%20ingin%20tanya%20perihal....&type=phone_number&app_absent=0'><img className='button-wa-img' src={ WaBtn } /></a>
        </div>
        </>
    );
};

export default Content;