import React, {useState} from 'react';

import { Container, Row, Col, Button, InputGroup, Form } from 'react-bootstrap';
import igLink from '../../assets/footer-instagram-icon.svg';
import tiktokLink from '../../assets/footer-tiktok-icon.svg';
import twitterLink from '../../assets/footer-social-twitter.svg';

import iconWA from '../../assets/footer-info-contact-wa.svg'
import iconEmail from '../../assets/footer-info-contact-email.svg'
import iconLocation from '../../assets/footer-info-contact-location.svg'

import axios from 'axios'

const Footer = () => {
    const [showMessageResp, setShowMessageResp] = useState(false);
    const handleCloseMessage = () => setShowMessageResp(false);
    const [formBody, setformBody] = useState({});

    const handleFormChange = (e) => {
        const updatedFormBody = {[e.target.name] : e.target.value}  
        setformBody( formBody => ({
            ...formBody,
            ...updatedFormBody,
        }));
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        axios.post('https://api.bioaqua.co.id/api/newsletter', formBody)
        .then(response => {
            setShowMessageResp(true);
            window.setTimeout(function() {
                setShowMessageResp(false);
            }, 1300);
        })
        .catch(errors => {
            console.log(errors);
        })
    }

    return (
        <>
            <div className='footer'>
                <div className='footer-info'>
                    <Container>
                        <Row>
                            <Col md={4} xs={12}>
                                <div className='footer-info-title-section'>
                                    <h4 className='footer-info-title'>
                                        CONTACT
                                    </h4>
                                </div>
                                <div className='footer-info-contact'>
                                    <Row>
                                        <Col md={1} xs={1}>
                                            <img className='footer-info-contact-icon' src={iconLocation} />
                                        </Col>
                                        <Col md={11} xs={11}>
                                            <p>PT PERMATA INDO KAV</p>
                                        </Col>
                                        <Col md={1} xs={1}>
                                            <img className='footer-info-contact-icon'  src={iconEmail} />
                                        </Col>
                                        <Col md={11} xs={11}>
                                            <p>Marketing@bioaqua.co.id</p>
                                        </Col>
                                        <Col md={1} xs={1}>
                                            <img className='footer-info-contact-icon'  src={iconWA} />
                                        </Col>
                                        <Col md={11} xs={11}>
                                            <a className='footer-info-contact-link' href='https://api.whatsapp.com/send?phone=6281223410002&text=Halo%20MinBio,%20aku%20ingin%20tanya%20perihal....&type=phone_number&app_absent=0'>Chat with Us</a>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='footer-info-title-section'>
                                    <h4 className='footer-info-title'>
                                        SOCIAL MEDIA
                                    </h4>
                                </div>
                                <div className='footer-info-social-media'>
                                    <a className='footer-info-social-media-link' href='https://www.instagram.com/bioaquabeautyofficial/' target='_blank'>
                                        <img src={igLink} />
                                    </a>
                                    <a className='footer-info-social-media-link' href='https://www.tiktok.com/@bioaquaofficialstore' target='_blank'>
                                        <img src={tiktokLink} />
                                    </a>
                                    <a className='footer-info-social-media-link' href='https://twitter.com/bioaquaofficial' target='_blank'>
                                        <img src={twitterLink} />
                                    </a>
                                </div>
                                <div className='footer-info-title-section'>
                                    <h4 className='footer-info-title'>
                                        OPERATION HOUR
                                    </h4>
                                </div>
                                <p className='footer-info-operasional-time'>
                                    MONDAY - SATURDAY : 09.00 - 18.00
                                </p>
                            </Col>
                            <Col md={8} xs={12} className='footer-info-action-section'>
                                <a className='footer-info-link' href='https://api.whatsapp.com/send?phone=6281223410002&text=Hai%20kak,aku%20baru%20saja%20lihat%20website%20bioaqua%20dan%20ingin%20join%20reseller'>
                                    JOIN AS <span className='footer-info-link-highlight'>RESELLER</span>
                                </a> 
                                <span className='footer-info-link-separator'> | </span>
                                <a className='footer-info-link' href='/career'>
                                    OUR <span className='footer-info-link-highlight'>CAREER</span>
                                </a>
                                <span className='footer-info-link-separator'> | </span>
                                <a className='footer-info-link' href='/store'>
                                    OUR <span className='footer-info-link-highlight'>STORE</span>
                                </a>
                                <div className='footer-info-newsletter'>
                                    <h2>Newsletter</h2>
                                    <center>
                                    <InputGroup className="mb-3 footer-info-newsletter-input-group">
                                        <Form.Control
                                            name='email'
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            className='footer-info-newsletter-form'
                                            onChange={handleFormChange}
                                        />
                                        <Button className='footer-info-newsletter-submit-btn' variant="outline-secondary" id="button-addon2" onClick={handleFormSubmit}>
                                            JOIN
                                        </Button>
                                    </InputGroup>
                                    </center>
                                </div>
                                
                                <p className='footer-info-copyright'>
                                    &copy;2022  BIOAQUA Official
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Footer;