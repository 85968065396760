import React, {useState} from 'react'
import WebNavbar from '../../components/navbar'
import Footer from '../../components/footer'
import Store from '../../components/store'

import { Helmet } from "react-helmet";

const Home = () => {
    const [showRegister, setShowRegister] = useState(false);

    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Store Location</title>
            <link rel="canonical" href="https://bioaqua.co.id/store" />
            <meta name="description" content="Looking for a Bioaqua store near you? Use our store locator tool to find the closest Bioaqua store location and get directions. Find all your favorite skincare products in-store today!"/>
          </Helmet>
          <WebNavbar showRegister={showRegister} setShowRegister={setShowRegister} />
          <Store />
          <Footer />
        </>
      );
}

export default Home;