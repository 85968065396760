import React, {useEffect, useState} from 'react'
import { Helmet } from "react-helmet";
import moment from 'moment'
import axios from 'axios'

import WebNavbar from '../../components/navbar'
import Footer from '../../components/footer'
import ButtonWa from '../../components/button-wa'
import Sidebar from '../../components/sidebar'

const Home = () => {
    const [showRegister, setShowRegister] = useState(false);
    const [chats, setChats] = useState([])

    const handleSubmitMessageEnter = (e) => {
      if(e.key === "Enter"){
          let contenteditable = document.querySelector('[contenteditable]'),
          text = contenteditable.textContent;
          e.preventDefault();
          document.querySelector('[contenteditable]').innerHTML = '';
  
          let formBody = {
              'text': text
          }
  
          let token = sessionStorage.getItem("token");
          let headers = {
              'Authorization': token
          }
  
          axios.patch('https://api.bioaqua.co.id/api/tickets/chat/user', formBody, {
              headers: headers
          })
          .then(response => {
              window.location.reload();
          })
          .catch(errors => {
              console.log(errors)
          })
      }
    }

    useEffect(() => {
      let token = sessionStorage.getItem("token");
  
      let headers = {
        'Authorization': token
      }
  
      fetch(
          `https://api.bioaqua.co.id/api/tickets/chat/user`,
          {
            method: "GET",
            headers: headers
          }
      )
      .then(res => res.json())
      .then(response => {
          setChats(response);
          console.log(chats.length)
      })
      .catch(error => console.log(error));
    }, []);

    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - User Member Ticket</title>
            <link rel="canonical" href="https://bioaqua.co.id/member/ticket" />
            <meta name="description" content="Welcome to the Bioaqua consultation page. Here you can access the dashboard to create a ticket and receive personalized assistance from our team of experts."/>
          </Helmet>
          <WebNavbar showRegister={showRegister} setShowRegister={setShowRegister} />
          <div className='member-dashboard'>
            <Sidebar />
            <div className='main-dashboard'>
              <div className='tickets-rooms-chatbox'>
                  <div className='tickets-rooms-chatbox-container'>
                      {
                        chats.length <= 0 ? (
                          <h3 className='tickets-rooms-message-welcome'>Hi, Selamat Datang dilayanan konsultasi BioAqua, ada yang bisa kami bantu ?</h3>
                        ) : (<></>)
                      }
                      <ul className='tickets-chats-chatbox-ul'>
                      {
                          chats.map((chat, index) => (
                              <li key={index} className='tickets-chats-chatbox-li'>
                              <div className={`${chat.source == 0? 'tickets-chats-message-admin' : 'tickets-chats-message-user'}`}>
                                  <div className={`tickets-chats-bubble ${chat.source == 0? 'tickets-chats-bubble-admin' : 'tickets-chats-bubble-user'}`}>
                                      <p className='tickets-chats-paragraph'>{chat.text}</p>
                                      <p className='tickets-chats-time'>{moment(chat.created_at).utc().format("D MMM YYYY - hh:mm A")}</p>
                                  </div>
                              </div>
                              </li>
                          ))
                      }
                      </ul>
                  </div>
              </div>
              <div 
                  className="tickets-rooms-chat-input-text" 
                  contentEditable
                  placeholder="Send Message..."
                  onKeyDown={handleSubmitMessageEnter}
                  >
              </div>
            </div>
          </div>
          <Footer />
          <ButtonWa />
        </>
      );
}

export default Home;