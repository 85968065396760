import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import BioSquad from '../../assets/home-menu-btn-biosquad.png'
import Ori from '../../assets/home-menu-btn-original.svg'
import Protection from '../../assets/home-menu-btn-bioprotection.svg'
import Certified from '../../assets/home-certified.png'

const HomeMenuButton = () => {
    return (
        <div className='home-menu-button'>
            <div className='home-menu-button-certification'>
                <img className='home-menu-button-certification-img' src={Certified} />
            </div>
            <Container>
                <Row>
                    <Col md={4} xs={4} className='home-menu-btn-col'>
                        <a href='/biosquad'>
                            <img className='home-menu-button-link-img' src={BioSquad} />
                        </a>
                    </Col>
                    <Col md={4} xs={4} className='home-menu-btn-col'>
                        <a href='http://ori.bioaqua.co.id/'>
                            <img className='home-menu-button-link-img' src={Ori} />
                        </a>
                    </Col>
                    <Col md={4} xs={4} className='home-menu-btn-col'>
                        <a href='https://api.whatsapp.com/send?phone=6285174208275&text=Halo%20MinBio,%20aku%20ingin%20tanya%20perihal....&type=phone_number&app_absent=0'>
                            <img className='home-menu-button-link-img' src={Protection} />
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HomeMenuButton;