import React, { useEffect, useState } from 'react'
import { Container, Form, Button } from 'react-bootstrap'
import WebNavbar from '../../components/navbar'
import Footer from '../../components/footer'

import axios from 'axios'

import { Helmet } from "react-helmet";

const Home = () => {
    const [showRegister, setShowRegister] = useState(false);
    const [questioner, setQuestioner] = useState([{}]);

    useEffect(() => {
        fetch(
            `https://api.bioaqua.co.id/api/questions`,
            {
              method: "GET"
            }
        )
        .then(res => res.json())
        .then(response => {
            setQuestioner(response);
        })
        .catch(error => console.log(error));
    }, [])

    const [formBody, setFormBody] = useState([{}]);

    const handleSelectQuestions = (e) => {
        let index = e.target.getAttribute('data-index')
        let questionsForm = formBody;
        if(questionsForm[index] != "") {
            questionsForm[index] = {
                question: e.target.name,
                answer: e.target.value
            } 
        } else {
            let newQuestionsForm = {
                question: e.target.name,
                answer: e.target.value
            }
            questionsForm.push(newQuestionsForm);
        }
        setFormBody(questionsForm);
    }

    const handlePatchQuestioner = (e) => {
        e.preventDefault();
        let token = sessionStorage.getItem("token");

        let headers = {
            'Authorization': token
        }

        let body = {
            'questioner': formBody
        }

        axios.patch('https://api.bioaqua.co.id/api/auth/questioner', body,
        {
            headers: headers
        })
        .then(response => {
            if(response.status == 200) {
                window.location = '/';
                return
            }
            window.location = '/registration/confirmation';
            return
        })
        .catch(errors => {
            console.log(errors)
        })
    }

    return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Registration Questioner</title>
            <link rel="canonical" href="https://bioaqua.co.id/questioner" />
            <meta name="description" content="Here are some commonly asked registration questions for Bioaqua. Use this questioner to ensure a smooth and hassle-free registration process."/>
          </Helmet>
          <WebNavbar showRegister={showRegister} setShowRegister={setShowRegister} />
          <div className='questioner'>
                <Container>
                    <div className='questioner-title'>
                        <p className='questioner-title-text'>
                            Questionnare
                        </p>
                    </div>
                    <div className='questioner-header'>
                        <p>
                        Untuk melanjutkan ke halaman member, mohon isi data berikut ya BAestie
                        </p>
                    </div>
                    <div className='questioner-content'>
                        <Form>
                            {
                                questioner &&
                                questioner.map((question, key) => (
                                    <Form.Group key={key} className="home-basties-form-group mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>{question.questions}</Form.Label>
                                        <Form.Select data-index={key} name={question.id} defaultValue='' className='home-basties-form-select' aria-label="Default select example" onChange={handleSelectQuestions}>
                                            <option disabled value=''>Pilih Jawaban</option>
                                            {
                                                question.answers &&
                                                question.answers.map((answer, aKey) => (
                                                    <option key={aKey} value={answer.id}>{answer.answer}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                ))
                            }
                            <Button className='home-basties-form-button' variant='primary' onClick={handlePatchQuestioner}>
                                Bergabung Sekarang
                            </Button>
                        </Form>
                    </div>
                </Container>
            </div>
          <Footer />
        </>
      );
}

export default Home;