import React from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'

const ContactUs = () => {
    return (
        <>
            <div className='contact-us'>
                <Container>
                    <div className='contact-us-title'>
                        <p className='contact-us-title-text'>
                            Contact Us
                        </p>
                    </div>
                    <div className='contact-us-header'>
                        <p>
                            Feel Free to Contact Us if You have something to disscuss about.
                        </p>
                    </div>
                    <div className='contact-us-content'>
                        <Row>
                            <Col md={5}>
                                <div className='contact-us-info'>
                                    <div className='contact-us-info-office'>
                                        <h3>Office Address</h3>
                                        <div className='contact-us-info-office-text'>
                                            <p>BIOAQUA SHOWROOM</p>
                                            <p>Jl. Pulau Maju Bersama Ruko RGIH No.20-21, Jakarta 14460</p>
                                        </div>
                                    </div>
                                    <div className='contact-us-info-email'>
                                        <h3>Email Address</h3>
                                        <p>info@bioaqua.co.id</p>
                                    </div>
                                    <div className='contact-us-info-phone'>
                                        <h3>Customer Services</h3>
                                        <p>+(62) 81223410002</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={7}>
                                <div className='contact-us-message'>
                                    <Form>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control type="text" placeholder="Your Name" name="name" className="contact-us-message-form" />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control type="text" placeholder="Your Email" name="email" className="contact-us-message-form"/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control type="text" placeholder="Subject" name="subject" className="contact-us-message-form"/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control as="textarea" rows={10} placeholder="Message" name="message" className="contact-us-message-form"/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <div className='contact-us-message-btn'>
                                            <Button variant="primary" className='contact-us-message-btn-send' type="submit">
                                                Send Message
                                            </Button>
                                            <div className='contact-us-message-btn-alternatives'>
                                                <p>or</p>
                                                <a href="https://api.whatsapp.com/send?phone=6281223410002">
                                                    Chat Whatsapp
                                                </a>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default ContactUs;