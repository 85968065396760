import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Carousel from 'react-grid-carousel'

import styles from '../../styles/articlehome.module.css';

import ArticleCard from '../article-card'

const Content = () => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        axios.get('https://api.bioaqua.co.id/api/article/post/query/withfilter?limit=3')
        .then(response => {
            setArticles(response.data.data);
        })
        .catch(errors => {
            console.log(errors);
        })
    }, []);

    return (
        <>
        <div className={styles.main}>
            <h1 className={styles.title}>New Articles</h1>
            <Container>
                <Carousel cols={3} rows={1} gap={0} containerClassName='product-list-content-container' loop>
                    {
                        articles ? (
                        articles.map((article, index) => (
                        <Carousel.Item key={index}>
                            <ArticleCard 
                                data={article}
                            />
                        </Carousel.Item>
                        ))
                        ) : (
                        <>
                            <center>
                                <p className={styles.nulltext}>There are no articles yet</p>
                            </center>
                        </>
                        )
                    }
                </Carousel>
            </Container>
        </div>
        </>
    );
};

export default Content;