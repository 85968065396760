import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import tsindosummit from './../../assets/tiktok-shop-indonesia-summit.svg'
import tsno1beautybrand from './../../assets/tiktok-shop-no1-beauty-brand.svg'

const HomeAbout = () => {
    return (
        <div className='home-about'>
            <Container>
                <div className='home-about-content'>
                    <Row>
                        <Col md={5}>
                            <h1 className='home-about-content-title'>Inspired By You!</h1>
                            <div className='home-about-content-text-section'>
                                <p className='home-about-content-paragraph'>We believe in fast innovation to bring our commitment to always making Cost-Efficient beauty with great value</p>
                                <p className='home-about-content-paragraph'>From Head-to-Toe, A-Z BIOAQUA provides a lifestyle for your beauty and personal care.</p>
                            </div>
                            <div className='home-about-content-award-mobile'>
                                <img className='home-about-content-award-mobile-img' src={tsno1beautybrand} />
                                <img className='home-about-content-award-mobile-img' src={tsindosummit} />
                            </div>
                            <a href='/about'>
                                <Button className='home-about-content-link'>
                                    Discover More
                                </Button>
                            </a>
                        </Col>
                        <Col>
                            <div className='home-about-content-award'>
                                <img src={tsno1beautybrand} />
                                <img src={tsindosummit} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default HomeAbout;