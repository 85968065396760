import React, {useState} from 'react'
import WebNavbar from '../../components/navbar'
import ForgotPasswordForm from '../../components/forgot-password-form'
import Footer from '../../components/footer'
import ButtonWa from '../../components/button-wa'

import { Helmet } from "react-helmet";

const Home = () => {
    const [showRegister, setShowRegister] = useState(false);
    const [questions, setQuestions] = useState([{}]);

    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Forgot Password</title>
            <link rel="canonical" href="https://bioaqua.co.id/password/forgot/reset" />
            <meta name="description" content="If you have forgotten your password for your Bioaqua account, don't worry! Our forgot password page will guide you through the steps to reset your password and regain access to your account."/>
          </Helmet>
          <WebNavbar questions={questions} showRegister={showRegister} setShowRegister={setShowRegister} />
          <ForgotPasswordForm section={'password'} />
          <Footer />
          <ButtonWa />
        </>
      );
}

export default Home;