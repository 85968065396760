import React, { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';

import { useSearchParams } from "react-router-dom"

import styles from '../../styles/forgotpassword.module.css';

import logo from '../../assets/main-logo-blue.png'

const Content = ({section}) => {
    let [searchParams, setSearchParams] = useSearchParams();
    var CryptoJS = require("crypto-js");

    useEffect(()=>{
        if(section == "password") {
            var tempEmail = localStorage.getItem("fpassmail");
            if(tempEmail == "" || tempEmail == undefined || tempEmail == null){
                window.location = '/';
                return
            }
            
            let q = searchParams.get("q");
            if(q == ""){
                localStorage.removeItem("fpassmail");
                window.location = '/';
                return
            }
            var decryptedEmail = CryptoJS.AES.decrypt(tempEmail, 'bioaqua-co-id-2022-secret').toString(CryptoJS.enc.Utf8);
            let email = decryptedEmail.replaceAll('"', '');
            const updatedFormBody = {["email"] : email}  
            setformBody( formBody => ({
                ...formBody,
                ...updatedFormBody,
            }));

            return;
        }
    }, []);

    const [formBody, setformBody] = useState({});

    const handleFormChange = (e) => {
        const updatedFormBody = {[e.target.name] : e.target.value}  

        if(e.target.name == "password_confirm"){
            if(formBody.password != e.target.value) {
                setErrMessage("Confirmation Password must matched with The New Password");
                return
            }

            setErrMessage("")
        }
    
        setformBody( formBody => ({
            ...formBody,
            ...updatedFormBody,
        }));
    }

    const handleFormChangePassword = (e) => {
        e.preventDefault();
        axios.post('https://api.bioaqua.co.id/api/auth/password/change', formBody)
        .then(response => {
            localStorage.removeItem("fpassmail");
            window.setTimeout(function() {
                window.location = '/password/forgot/success';
            }, 1000);
        })
        .catch(errors => {
            console.log(errors);
            setErrMessage(errors.response.data.message);
        })
    }

    const handleFormSubmitRequest = (e) => {
        e.preventDefault();
        var encryptedEmail = CryptoJS.AES.encrypt(JSON.stringify(formBody.email), 'bioaqua-co-id-2022-secret').toString();
        formBody.link = 'https://bioaqua.co.id/password/forgot/reset?q=' + encryptedEmail;
        axios.post('https://api.bioaqua.co.id/api/auth/password/forgot', formBody)
        .then(response => {
            localStorage.setItem("fpassmail", encryptedEmail);
            window.setTimeout(function() {
                window.location = '/password/forgot/confirmation';
            }, 1000);
        })
        .catch(errors => {
            console.log(errors);
            setErrMessage(errors.response.data.message);
        })
    }

    const [errMessage, setErrMessage] = useState('');

    const handleBackToHome = () => {
        localStorage.removeItem("fpassmail");
        window.location = '/';
    }

    return (
        <>
        <Container className={styles.main}>
            <div className={styles.contentsection}>
                <div className={styles.titlesection}>
                    {
                        section == "password" ? (
                            <h2 className={styles.title}>INPUT YOUR NEW PASSWORD</h2>
                        ) : (
                            <h2 className={styles.title}>RESET YOUR PASSWORD</h2>
                        )
                    }
                </div>
                <div className={styles.subtitlesection}>
                    {
                        section == "password" ? (
                            <p className={styles.subtitle}>Input your new password. Please use the password that you will easily remember</p>
                        ) : (
                            <p className={styles.subtitle}>Fear Not. Just Enter your email address and we will send you an instruction to reset your password to your email. You will receive a link to change your password via email</p>
                        )
                    }
                </div>
                <div className={styles.bodysection}>
                    {
                        section == "password" ? (
                            <Form onSubmit={handleFormChangePassword}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control className={styles.formpassword} name="password" type="password" placeholder="Input Your New Password" onChange={handleFormChange} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>New Password Confirmation</Form.Label>
                                    <Form.Control className={styles.formpassword} name="password_confirm" type="password" placeholder="Input Your Password Confirmation" onChange={handleFormChange}/>
                                </Form.Group>
                                <p className={styles.errormessage}>{errMessage}</p>
                                <div className={styles.bodybtn}>
                                    <Button variant='warning' type='submit' className={styles.resetpassbtn}>Change Your Password</Button>
                                    <Button variant='light' className={styles.backbtn} onClick={handleBackToHome}>Back to Home</Button>
                                </div>
                            </Form>
                        ) : (
                            <Form onSubmit={handleFormSubmitRequest}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control name="email" type="email" placeholder="example@mail.com" onChange={handleFormChange} />
                                </Form.Group>
                                <p className={styles.errormessage}>{errMessage}</p>
                                <div className={styles.bodybtn}>
                                    <Button variant='warning' type='submit' className={styles.resetpassbtn}>Reset Password</Button>
                                    <a href={"/"}><Button variant='light' className={styles.backbtn}>Back to Home</Button></a>
                                </div>
                            </Form>
                        )
                    }
                </div>
                <div className={styles.footersection}>
                    <img src={logo} />
                </div>
            </div>
        </Container>
        </>
    );
}

export default Content;