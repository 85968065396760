import React from 'react';
import { Container } from 'react-bootstrap';
import styles from '../../styles/bodymessage.module.css';

import logo from '../../assets/main-logo-blue.png';

const Content = ({title, body, metode, metodelink}) => {
    return (
        <>
        <Container className={styles.main}>
            <div className={styles.titlesection}>
                <h2 className={styles.title}>{title}</h2>
            </div>
            <div className={styles.textsection}>
                <p className={styles.text}>{body}</p> 
                {metode == 'email' ? (
                    <span className={styles.text}>
                        Click here if you didnt receive the email: <a href={metodelink} className={styles.spanlink}>resend email</a>
                    </span>
                ):(
                    <></>
                )} 
            </div>
            <img className={styles.image} src={logo}/>
        </Container>
        </>
    );
}

export default Content;