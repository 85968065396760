import { Container } from 'react-bootstrap';

const ProductCard = ({product, section}) => {
    const handleProductDetail = (product) => {
        window.location = '/products/'+product.name
    }

    return (
        <>
            <div className='product-card' onClick={() => handleProductDetail(product)}>
                <Container>
                    <div className={`product-card-img-section`}>
                        <img className='product-card-img' src={'https://api.bioaqua.co.id/products/'+product.previewImg} />
                    </div>
                    <div className='product-card-text-section'>
                        <p className='product-card-name'>
                            {product.display_name}
                        </p>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default ProductCard;