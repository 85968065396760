import loadable from '@loadable/component'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Helmet } from "react-helmet";

const Navbar = loadable(() => import('../../components/navbar'))
const Footer = loadable(() => import('../../components/footer'))
const ArticleDetail = loadable(() => import('../../components/article-detail'))

const Home = () => {
  const [data, setData] = useState({});
  const { name } = useParams();
  const [showRegister, setShowRegister] = useState(false);

  useEffect(() => {
    fetch(
        `https://api.bioaqua.co.id/api/article/post/query/withfilter?row=single&title=`+encodeURIComponent(name),
        {
          method: "GET"
        }
        )
        .then(res => res.json())
        .then(response => {
          setData(response);
        })
        .catch(error => console.log(error));
}, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BioAqua - Articles Detail</title>
        <link rel="canonical" href={"https://bioaqua.co.id/articles/"+data.title} />
        <meta name="description" content="Welcome to the Bioaqua articles page where you can find informative articles on skincare, beauty routines, and the latest trends in the industry. Keep up with the latest news and tips on achieving a healthy and glowing complexion with Bioaqua."/>
      </Helmet>
      <Navbar showRegister={showRegister} setShowRegister={setShowRegister} />
      <ArticleDetail data={data} />
      <Footer />
    </>
  )
}

export default Home;