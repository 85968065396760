import React, { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom";
import WebNavbar from '../../components/navbar'
import BodyMessage from '../../components/body-message'
import Footer from '../../components/footer'

import axios from 'axios'

import { Helmet } from "react-helmet";

const Home = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [showRegister, setShowRegister] = useState(false);

    useEffect(() => {
        let token = searchParams.get("q");
        axios.get('https://api.bioaqua.co.id/api/auth/confirmation?token='+token)
        .then(response => {
            window.location = '/';
            return
        })
        .catch(errors => {
            console.log(errors)
        })
    }, [])

    return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Registration Confirmation</title>
            <link rel="canonical" href="https://bioaqua.co.id/registration/confirmation/verify" />
            <meta name="description" content="Thank you for registering with Bioaqua! verify your registration! Find out more about our products and services on our website."/>
          </Helmet>
          <WebNavbar showRegister={showRegister} setShowRegister={setShowRegister} />
          <BodyMessage title={"REGISTRATION COMPLETED"} body={"Your Registration process is completed. We will logging in your account and redirecting you to home page"}/>
          <Footer />
        </>
      );
}

export default Home;