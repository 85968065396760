import React, { useState } from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

import styles from '../../styles/login-modal.module.css';

import separator from '../../assets/or-separator.svg';

const Content = ({show, onHide, clickSignUp}) => {
    const [formBody, setformBody] = useState({});

    const handleFormChange = (e) => {
        const updatedFormBody = {[e.target.name] : e.target.value}  
        setformBody( formBody => ({
            ...formBody,
            ...updatedFormBody,
        }));
    }

    const [errMessage, setErrMessage] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        axios.post('https://api.bioaqua.co.id/api/auth/token', formBody)
        .then(response => {
            sessionStorage.setItem('token_expired', response.data.expired_at);
            sessionStorage.setItem('token', response.data.token);
            if(response.status == 206) {
                window.location = '/questioner'
                return
            } else if(response.status == 202) {
                window.location = '/registration/confirmation'
                return
            }

            window.setTimeout(function() {
                window.location.reload();
            }, 1000);
        })
        .catch(errors => {
            console.log(errors)
        })
    }

    const responseGoogleSuccess = (response) => {
        var formBody = {
            email: response.email,
            name: response.name,
            googleId: response.sub,
            flag: 'google'
        }

        axios.post('https://api.bioaqua.co.id/api/auth/google', formBody)
        .then(response => {
            sessionStorage.setItem('token_expired', response.data.expired_at);
            sessionStorage.setItem('token', response.data.token);

            if(response.status == 206) {
                window.location = '/questioner'
                return
            }

            window.setTimeout(function() {
                window.location.reload();
            }, 1000);
        })
        .catch(errors => {
            console.log(errors);
            setErrMessage(errors.response.data.message)
        })
    }

    const responseGoogleFail = (response) => {
        console.log('[fail] error :', response);
        setErrMessage('Gagal Login, Terjadi Kesalahan Server')
    }
    
    return (
        <>
        <Modal show={show} onHide={onHide} className={styles.main}> 
            <Modal.Header closeButton className={styles.header}>
                <Modal.Title className={styles.title}>Login</Modal.Title><br/>
            </Modal.Header>
            <div className={styles.subtitlesection}>
                <p className={styles.subtitle}>Don&apos;t have an account? <a className={styles.registerlink} onClick={clickSignUp}>Create one</a></p>
            </div>
            <Modal.Body className={styles.body}>
                <Form className={styles.form} onSubmit={handleFormSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className={styles.formlabel}>Email</Form.Label>
                        <Form.Control name='email' type="email" placeholder="Enter email" onChange={handleFormChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className={styles.formlabel}>Password</Form.Label>
                        <Form.Control name='password' type="password" placeholder="Password" onChange={handleFormChange}/>
                    </Form.Group>
                    <p className={styles.errormessage}>{errMessage}</p>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Remember Me" />
                            </Form.Group>
                        </Col>
                        <Col className={styles.forgotpassword}>
                            <a href={'/password/forgot'}>Forgot Password</a>
                        </Col>
                    </Row>
                    <Button variant="dark" type="submit" className={styles.submitbtn}>
                        Login
                    </Button>
                </Form>
            
                <img src={separator} className={styles.separator} />
                <center>
                    <GoogleLogin
                    onSuccess={credentialResponse => {
                        var decoded = jwt_decode(credentialResponse.credential);
                        responseGoogleSuccess(decoded);
                    }}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                    size={'large'}
                    shape={'circle'}
                    width={'400'}
                    />;
                </center>
            </Modal.Body>   
        </Modal>
        </>
    );
};

export default Content;