import React, {useEffect, useState} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'

import carerrBanner from '../../assets/career-banner.png'

const CareerBrowse = ({careers}) => {
    return (
        <>
            <div className='career-browse'>
                <Container>
                    <div className='career-browse-title'>
                        <p className='career-browse-title-text'>
                            Career at Bioaqua
                        </p>
                    </div>
                    <div className='career-browse-header'>
                        <p>
                            Welcome to Careers at Bioaqua. What impact will you make? Are you ready to apply your knowledge and life experience to exciting new challenges?
                        </p>
                        <img src={carerrBanner} />
                    </div>
                    <div className='career-browse-career'>
                        {
                            careers != null ? (
                                <></>
                            ):(
                                <>
                                    <p className='career-browse-null-notifier'>Ooops, masih belum ada oppurtunity nih BAesties, nantikan info selanjutnya!</p>
                                    <a href='/'>
                                        <Button className='career-browse-back-home-btn'>
                                            Kembali ke Beranda
                                        </Button>
                                    </a>
                                </>
                            )
                        }
                    </div>
                </Container>
            </div>
        </>
    )
}

export default CareerBrowse;