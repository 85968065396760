import { Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import ProductCard from '../product-card';
import Carousel from 'react-grid-carousel'

const WhatsNew = ({productsNA, productsBS}) => {
    return (
        <>
            <div id='whats-new' className='home-whats-new'>
                <div className='home-product-new-arrival'>
                    <div className='home-product-title'>
                    <h1 className='home-product-title-text'>
                        New Arrival
                    </h1>
                    </div>
                    <Container className='home-product-list'>
                        <Carousel 
                            cols={5} 
                            rows={1} 
                            gap={0} 
                            mobileBreakpoint={360}
                            responsiveLayout={[
                                {
                                    breakpoint: 768,
                                    cols: 2,
                                    gap: 0
                                }
                            ]}
                            containerClassName='home-product-list-content-container'
                            loop
                        >
                            {
                            productsNA ? (
                                productsNA.map((product, index) => (
                                <Carousel.Item key={index}>
                                    <div className='product-list-card'>
                                    <ProductCard product={product}/>
                                    </div>
                                </Carousel.Item>
                                ))
                            ) : (
                                <>
                                </>
                            )
                            }
                        </Carousel>
                    </Container>
                    <div className='home-product-btn-section'>
                        <a href='/products/new'>
                            <Button className='home-product-nav-btn'>See More <FontAwesomeIcon icon={faArrowRight} /></Button>
                        </a>
                    </div>
                </div>
                <div className='home-product-best'>
                    <div className='home-product-title'>
                    <h1 className='home-product-title-text'>
                        Best Seller
                    </h1>
                    </div>
                    <Container className='home-product-list'>
                        <Carousel 
                            cols={5} 
                            rows={1} 
                            gap={10} 
                            containerClassName='home-product-list-content-container' 
                            loop
                            mobileBreakpoint={360}
                            responsiveLayout={[
                                {
                                    breakpoint: 768,
                                    cols: 2,
                                    gap: 0
                                }
                            ]}
                        >
                            {
                            productsBS ? (
                                productsBS.map((product, index) => (
                                <Carousel.Item key={index}>
                                    <div className='product-list-card'>
                                    <ProductCard product={product}/>
                                    </div>
                                </Carousel.Item>
                                ))
                            ) : (
                                <>
                                </>
                            )
                            }
                        </Carousel>
                    </Container>
                    <div className='home-product-btn-section'>
                        <a href='/products/best'>
                            <Button className='home-product-nav-btn'>See More <FontAwesomeIcon icon={faArrowRight} /></Button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhatsNew;