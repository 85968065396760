import React from 'react';
import { Carousel } from 'react-bootstrap';

const HomeBanner = ({banner, bannerMobile}) => {
    return (
        <>
            <Carousel className='home-banner-carousel' indicators={true} controls={false} interval={3000}>
                {
                    banner.map((ban, index) => (
                        <Carousel.Item key={index}>
                            <a href={ban.link == "" ? null : ban.link}>
                                <img
                                className="d-block w-100 home-banner-img"
                                src={ban.src}
                                alt={'banner bioaqua '+index}
                                />
                            </a>
                        </Carousel.Item>
                    ))
                }
            </Carousel>
            <Carousel className='home-banner-carousel-mobile' indicators={true} controls={false} interval={3000}>
                {
                    bannerMobile.map((ban, index) => (
                        <Carousel.Item key={index}>
                            <a href={ban.link == "" ? null : ban.link}>
                                <img
                                className="d-block w-100 home-banner-img"
                                src={ban.src}
                                alt={'banner bioaqua '+index}
                                />
                            </a>
                        </Carousel.Item>
                    ))
                }
            </Carousel>
        </>
    )
}

export default HomeBanner;