import React, {useState, useEffect} from 'react'
import WebNavbar from '../../components/navbar'
import Footer from '../../components/footer'
import ProductCard from '../../components/product-card';
import { Button, Container, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { Helmet } from "react-helmet";
import Carousel from 'react-grid-carousel'

import Banner from './../../assets/product-new-banner.svg'
import BannerMobile from './../../assets/product-na-banner-mobile.png'

const Home = () => {
    const [showRegister, setShowRegister] = useState(false);
    const [products, setProducts] = useState([{}]);

    useEffect(() => {
        fetch(
            `https://api.bioaqua.co.id/api/products/category/52a202c1-74ff-4b60-91ff-cc1e55dd1e8a`,
            {
              method: "GET"
            }
            )
            .then(res => res.json())
            .then(response => {
                setProducts(response);
            })
            .catch(error => console.log(error));
    }, [])

    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Products</title>
            <link rel="canonical" href="https://bioaqua.co.id/products" />
            <meta name="description" content="Explore the full range of Bioaqua's products, including skincare, beauty, and wellness products, on our easy-to-use browse page. Discover the perfect solution for your skincare needs and start feeling confident in your skin."/>
          </Helmet>
          <WebNavbar showRegister={showRegister} setShowRegister={setShowRegister} />
          <div className='product-list'>
            <img className='product-list-banner' src={Banner} />
            <img className='product-list-banner-mobile' src={BannerMobile} />
            <div className='product-list-title'>
              <h1 className='home-product-title-text'>
                New Arrival
              </h1>
            </div>
            <div className='product-list-content'>
              <div className='product-list-content-desktop'>
              <Carousel cols={products.length >= 6 ? 6 : products.length} rows={products.length > 6 ? 2 : 1} gap={5} containerClassName='product-list-content-container' loop>
                {
                  products ? (
                    products.map((product, index) => (
                      <Carousel.Item key={index}>
                        <div className='product-list-card'>
                          <ProductCard product={product}/>
                        </div>
                      </Carousel.Item>
                    ))
                  ) : (
                    <>
                    </>
                  )
                }
              </Carousel>
              </div>
              <div className='product-list-content-mobile'>
                <Container className='product-list-content-container'>
                  <Row>
                    {
                      products ? (
                        products.map((product, index) => (
                          <Col md={2} xs={4} key={index} className='product-list-content-row'>
                            <div className='product-list-card'>
                              <ProductCard product={product} section={'product'} />
                            </div>
                          </Col>
                        ))
                      ) : (
                        <>
                        </>
                      )
                    }
                  </Row>
                </Container>
              </div>
            </div>
            <div className='home-product-btn-section product-list-btn-section'>
              <a href='/products'>
                <Button className='home-product-nav-btn'>See More <FontAwesomeIcon icon={faArrowRight} /></Button>
              </a>
            </div>
          </div>
          <Footer />
        </>
      );
}

export default Home;