import loadable from '@loadable/component'
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Carousel, Form } from 'react-bootstrap';
import moment from "moment";
import sanitizeHtml from 'sanitize-html';

import IconTwitter from '../../assets/icon-twitter-blue.svg'
import IconInstagram from '../../assets/icon-instagram-blue.svg'
import IconTiktok from '../../assets/icon-tiktok-blue.svg'

import Logo from '../../assets/main-logo-blue.png'

import { Helmet } from "react-helmet";

import { useLocation } from "react-router-dom";

import ClipLoader from "react-spinners/ClipLoader";

const Navbar = loadable(() => import('../../components/navbar'))
const Footer = loadable(() => import('../../components/footer'))

const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};


const Home = () => {
  const [showRegister, setShowRegister] = useState(false);
  
  const [banner, setBanner] = useState([]);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [latest, setLatest] = useState([]);

  const fbIframe = '<iframe class="fb-embed-iframe" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100085924106336&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1632446757225504" width="340" height="130" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'

  const [indexBanner, setIndexBanner] = useState(0);

  const handleSelectIndexBanner = (selectedIndex, e) => {
    setIndexBanner(selectedIndex);
  };

  const itemsPerPage = 4; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const { search, pathname } = useLocation();

  const getPageParam = () => {
    const params = new URLSearchParams(search);
    return params.get('page');
  }

  const getCategoryParam = () => {
    const params = new URLSearchParams(search);
    return params.get('category');
  }

  const [isLoading, setIsLoading] = useState(true);

  const fetchCategory = () => {
    return fetch(
      `https://api.bioaqua.co.id/api/article/category`,
      {
        method: "GET"
      }
      )
      .then(res => res.json())
      .catch(error => console.log(error));
  }

  const fetchArticles = (skip, limit, category) => {
    let urls = "https://api.bioaqua.co.id/api/article/post/query/withfilter"
    if(limit!=undefined&&limit!=0){
      urls += "?limit=" + limit 
    }

    if(skip!=undefined&&skip!=0) {
      if(urls.includes('?')){
        urls += "&skip=" + skip
      } else {
        urls += "&skip=" + skip
      }
    }

    if(category!=undefined&&category!=''&&category!='all'){
      if(urls.includes('?')){
        urls += "&category_name=" + category
      } else {
        urls += "?category_name=" + category
      }
    }

    return fetch(
      urls,
      {
        method: "GET"
      }
      )
      .then(res => res.json())
      .catch(error => console.log(error));
  }

  const fetchData = () => {
    setIsLoading(true)

    let page = 1;
    let pageValue = getPageParam();
    if(pageValue!=null&&pageValue!=undefined&&pageValue!=""){
      const pageNumber = parseInt(pageValue, 10);
      setCurrentPage(pageNumber)
      page = pageNumber;
    }

    let skip = 0;
    if(page > 1){
      skip = (page-1)*itemsPerPage;
    }

    let categoryFilter = '';
    let categoryValue = getCategoryParam();
    if(categoryValue!=null&&categoryValue!=undefined&&categoryValue!=""){
      categoryFilter = categoryValue;
      setSelectedCategory(categoryValue);
    }
    console.log(categoryValue);

    Promise.all([fetchCategory(), fetchArticles(skip, itemsPerPage, categoryFilter), fetchArticles(0, 3, '')])
    .then(([datacategory, datas, popular]) => {
      setCategory(datacategory);
      setTotalPage(Math.ceil(datas.total/itemsPerPage));
      setData(datas.data);
      setBanner(popular.data);
      setLatest(popular.data);
      setIsLoading(false);
    })
    .catch(error => {
      console.error('Error with Promise.all:', error);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
   fetchData();
  }, [])

  const [selectedCategory, setSelectedCategory] = useState('');

  const handleSelectCategory = (e) => {
    let cat = e.currentTarget.getAttribute('data-category');
    const searchParams = new URLSearchParams(search);
    searchParams.set('category', cat);
    if(cat=='all'){
      searchParams.delete('category');
    }
    const newSearch = searchParams.toString();
    window.location = `${pathname}?${newSearch}`;
  }

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      let value = e.currentTarget.value
      window.location = '/articles/browse?search='+value;
    }
  }

  const handlePageChange = (page) => {
    const searchParams = new URLSearchParams(search);
    searchParams.set('page', page);
    const newSearch = searchParams.toString();
    window.location = `${pathname}?${newSearch}`;
  };

  useScript("https://apis.google.com/js/platform.js")

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BioAqua - Articles</title>
        <link rel="canonical" href="https://bioaqua.co.id/articles" />
        <meta name="description" content="Welcome to the Bioaqua articles page where you can find informative articles on skincare, beauty routines, and the latest trends in the industry. Keep up with the latest news and tips on achieving a healthy and glowing complexion with Bioaqua."/>
      </Helmet>
      <Navbar showRegister={showRegister} setShowRegister={setShowRegister} />
        {
          isLoading ?
          (
            <>
              <ClipLoader
                className='article-home-loading'
                color={`#083687`}
                loading={isLoading}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </>
          ) : (
            <>
              <div className='article-home'>
                      <div className='article-home-header'>
                  <Container className='article-home-header-container'>
                    <Row>
                      <Col className='article-home-header-social'>
                        <a href='https://www.instagram.com/bioaquabeautyofficial/'>
                          <img src={IconInstagram} />
                        </a>
                        <a href='http://tiktok.com/@bioaqua%E2%80%A6'>
                          <img src={IconTiktok} />
                        </a>
                        <a href='https://twitter.com/bioaquaofficial'>
                          <img src={IconTwitter} />
                        </a>
                      </Col>
                      <Col md={7} xs={12} className='article-home-header-logo'>
                        <img src={Logo} />
                      </Col>
                      <Col xs={12} className='article-home-header-social-mobiles'>
                        <a href='https://www.instagram.com/bioaquabeautyofficial/'>
                          <img src={IconInstagram} />
                        </a>
                        <a href='http://tiktok.com/@bioaqua%E2%80%A6'>
                          <img src={IconTiktok} />
                        </a>
                        <a href='https://twitter.com/bioaquaofficial'>
                          <img src={IconTwitter} />
                        </a>
                      </Col>
                      <Col className='article-home-header-menu'>
                        <Form.Control className='article-home-header-menu-search' type="text" placeholder="Search..." onKeyDown={handleSearch} />
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className='article-home-banner'>
                  <div className='article-home-banner-content'>
                    <Container>
                      <Carousel activeIndex={indexBanner} onSelect={handleSelectIndexBanner} indicators={false}>
                        {
                          banner.map((article, index) => (
                            <Carousel.Item>
                              <img
                                className="article-home-banner-content-image"
                                src={'https://api.bioaqua.co.id/article/'+article.image}
                              />
                              <Carousel.Caption className='article-home-banner-content-caption'>
                                <a className='article-home-banner-content-caption-category'>{article.category}</a>
                                <h2 className='article-home-banner-content-caption-title'>{article.title}</h2>
                                <p className='article-home-banner-content-caption-timestamp'>{moment(article.created_at).format("MMMM D, YYYY")}</p>
                                <div className='article-home-banner-content-caption-content' dangerouslySetInnerHTML={{__html: sanitizeHtml(article.content, {allowedTags: [ 'p' ]})}} />
                                <a className='article-home-banner-content-caption-link' href={'/articles/'+encodeURIComponent(article.title)}>
                                  VIEW POST
                                </a>
                              </Carousel.Caption>
                            </Carousel.Item>
                          ))
                        }
                      </Carousel>
                    </Container>
                  </div>
                </div>
                <div className='article-home-category'>
                  <Container>
                    <Button 
                      data-id='0'
                      data-category='all'
                      variant="outline-primary" 
                      onClick={handleSelectCategory}
                      className={`article-home-category-button ${selectedCategory == '' ? 'category-selected' : ''}`}>
                      All
                    </Button>
                    {
                      category ? (
                        category.map((cat, index) => (
                          <Button 
                            key={index} 
                            data-id={cat.id}
                            data-category={cat.name}
                            variant="outline-primary" 
                            onClick={handleSelectCategory}
                            className={`article-home-category-button ${cat.name === selectedCategory ? 'category-selected' : ''}`}>
                              {cat.name}
                          </Button>
                        ))
                      ) : (
                        <>
                        </>
                      )
                    }
                  </Container>
                </div>
                <div className='article-home-content'>
                  <Container>
                    <Row>
                      <Col md={8} sm={12} xs={12}>
                        <Row>
                        {
                          data.length > 0 ?
                          data.map((article, index) => (
                            <Col key={index} md={6} xs={12}>
                              <div className='article-home-content-multi'>
                                <img className='article-home-content-multi-img' src={'https://api.bioaqua.co.id/article/'+article.image} />
                                <p className='article-home-content-category'>{article.category}</p>
                                <a className='article-home-content-link' href={'/articles/'+encodeURIComponent(article.title)}>
                                  <h3 className='article-home-content-title'>{article.title}</h3>
                                </a>
                                <p className='article-home-content-timestamp'>{moment(article.created_at).format("MMM D, YYYY")}</p>
                                <div className='article-home-content-paragraph' dangerouslySetInnerHTML={{__html: sanitizeHtml(article.content, {allowedTags: [ 'p' ]})}} />
                              </div>
                            </Col>
                          )) : (
                            <p>There are no article matches. Try different search</p>
                          )
                        }
                        </Row>
                          {totalPage > 1 && (
                            <nav className='article-home-pagination' aria-label="Page navigation example">
                              <ul className="pagination justify-content-end">
                              {Array.from({ length: totalPage }, (_, index) => (
                                <li 
                                  className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}
                                  key={index} 
                                  onClick={() => handlePageChange(index + 1)}
                                  active={index + 1 === currentPage}>
                                    <a class="page-link" href="#" disabled={index + 1 === currentPage}>
                                  {index + 1}
                                  </a>
                                </li>
                              ))}
                              </ul>
                            </nav>
                          )}
                      </Col>
                      <Col md={4} xs={12}>
                        <div className='article-home-content-latest'>
                          <h4 className='article-home-content-latest-title'>Popular Articles</h4>
                          <div className='article-home-content-latest-list'>
                            {
                              latest.length != 0 ?
                                latest.map((article, index) => (
                                  <a key={index} href={'/articles/'+encodeURIComponent(article.title)} className='article-home-content-latest-link'>
                                    <Row className='article-home-content-latest-row'>
                                      <Col md={4} xs={4}>
                                        <img className='article-home-content-latest-img' src={'https://api.bioaqua.co.id/article/'+article.image} />
                                      </Col>
                                      <Col md={8} xs={8}>
                                        <h5>{article.title}</h5>
                                        <p>{moment(article.created_at).format("MMM D YYYY")} &#8729; {article.category}</p>
                                      </Col>
                                    </Row>
                                  </a>   
                                )): (
                                <>
                                  <center>
                                    <p>There are no articles yet</p>
                                  </center>
                                </>
                              )
                            }
                          </div>
                        </div>
                        <div className='article-home-content-fb-embed'>
                          <h5 className='article-home-content-embed-title'>Like Our Facebook</h5>
                          <div className='article-home-content-fb-embed-content' dangerouslySetInnerHTML={{__html: fbIframe}}></div>
                        </div>
                        <div className='article-home-content-yt-embed'>
                          <h5 className='article-home-content-embed-title'>Subscribe Our Youtube</h5>
                          <div className='article-home-content-yt-embed-content'>
                            <div
                              class="g-ytsubscribe"
                              data-channelid="UCBJXJfcvt4qYdfUKBOTwDpw"
                              data-layout="full"
                              data-count="hidden" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              <Footer />
            </>
          )
        }
      
    </>
  )
}

export default Home;