import React, {useState} from 'react'
import WebNavbar from '../../components/navbar'
import Footer from '../../components/footer'

import Basties from '../../components/home-basties'

import { Helmet } from "react-helmet";

const BioSquad = () => {
    const [showRegister, setShowRegister] = useState(false);
    const [questions, setQuestions] = useState({});

    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - BioSquad</title>
            <link rel="canonical" href="https://bioaqua.co.id/biosquad" />
            <meta name="description" content="Join the Bioaqua squad and be part of a community of skincare lovers. Sign up now and get exclusive access to new products, promotions, and tips for healthy and glowing skin."/>
          </Helmet>
          <WebNavbar showRegister={showRegister} setShowRegister={setShowRegister} questions={questions} />
          <Basties questions={questions} setQuestions={setQuestions} setShowRegister={setShowRegister} />
          <Footer />
        </>
      );
}

export default BioSquad;