import React, {useEffect, useState} from 'react'
import WebNavbar from '../../components/navbar'
import BodyMessage from '../../components/body-message'
import Footer from '../../components/footer'
import ButtonWa from '../../components/button-wa'

import axios from 'axios'

import { useSearchParams } from "react-router-dom"

import { Helmet } from "react-helmet";

const Home = () => {
    const [showRegister, setShowRegister] = useState(false);
    const [questions, setQuestions] = useState([{}]);

    let [searchParams, setSearchParams] = useSearchParams();
    var CryptoJS = require("crypto-js");
    
    useEffect(() => {
        let action = searchParams.get("action");
        if(action=="resend") {
          let encryptedEmail = localStorage.getItem("fpassmail");
          var decryptedEmail = CryptoJS.AES.decrypt(encryptedEmail, 'bioaqua-co-id-2022-secret').toString(CryptoJS.enc.Utf8);
          let email = decryptedEmail.replaceAll('"', '');
          let bodyRequest = {
            email: email,
            link: 'https://bioaqua.co.id/password/forgot/reset?q=' + encryptedEmail
          }
          axios.post('https://api.bioaqua.co.id/api/auth/password/forgot', bodyRequest)
          .then(response => {
            window.location = '/password/forgot/confirmation';
            return
          })
          .catch(errors => {
              console.log(errors)
          })
        }
      }, [])

    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Forgot Password</title>
            <link rel="canonical" href="https://bioaqua.co.id/password/forgot/confirmation" />
            <meta name="description" content="If you have forgotten your password for your Bioaqua account, don't worry! Our forgot password page will guide you through the steps to reset your password and regain access to your account."/>
          </Helmet>
          <WebNavbar questions={questions} showRegister={showRegister} setShowRegister={setShowRegister} />
          <BodyMessage title={"CHECK YOUR EMAIL"} body={"A Email for your password reset request confirmation has been sent to your email address. Go check your email and click the button to continue resetting your password"} metode={'email'} metodelink={'/password/forgot/confirmation?action=resend'} />
          <Footer />
          <ButtonWa />
        </>
      );
}

export default Home;