import React from 'react'
import {Container} from 'react-bootstrap'
import LogoutIcon from '../../assets/icon-logout.svg'

const Home = () => {
  const handleLogout = () => {
    sessionStorage.clear();
    window.location = '/';
  }

  return (
    <>
      <div className='sidebar'>
          <Container>
            <div className='sidebarheader'>
                <p>Member Area</p>
            </div>
            <div className='sidebar-menu-list'>
              <div className='sidebar-content'>
                <ul className='sidebarlist'>
                  <li 
                    onClick={()=>{window.location.pathname = '/member/ticket'}}
                    className='sidebarrow'
                  >
                    {" "}
                    <div>
                      Consultation Room
                      <span className={window.location.pathname.includes('/member/ticket' ) ? 'menuactive' : ''}></span>
                    </div>
                  </li>
                  <li 
                    onClick={()=>{window.location.pathname = '/member/profile'}}
                    className='sidebarrow'
                  >
                    {" "}
                    <div>
                      Profile
                      <span className={window.location.pathname.includes('/member/profile' ) ? 'menuactive' : ''}></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='sidebarfooter'>
                <li 
                onClick={handleLogout}
                className='logout'
                >
                    <img src={LogoutIcon} />
                    <span>Logout</span>
                </li>
            </div>
          </Container>
      </div>
    </>
  );
}

export default Home;
