import React, {useState, useEffect} from 'react'
import WebNavbar from '../../components/navbar'
import Footer from '../../components/footer'
import ProductCard from '../../components/product-card';

import { Helmet } from "react-helmet";
import {Container, Row, Col} from 'react-bootstrap';

const Home = () => {
    const [showRegister, setShowRegister] = useState(false);
    const [products, setProducts] = useState([{}]);

    useEffect(() => {
        fetch(
            `https://api.bioaqua.co.id/api/products`,
            {
              method: "GET"
            }
            )
            .then(res => res.json())
            .then(response => {
                setProducts(response);
            })
            .catch(error => console.log(error));
    }, [])

    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Products</title>
            <link rel="canonical" href="https://bioaqua.co.id/products" />
            <meta name="description" content="Explore the full range of Bioaqua's products, including skincare, beauty, and wellness products, on our easy-to-use browse page. Discover the perfect solution for your skincare needs and start feeling confident in your skin."/>
          </Helmet>
          <WebNavbar showRegister={showRegister} setShowRegister={setShowRegister} />
          <div className='product-list'>
            <div className='product-list-title'>
              <h1 className='home-product-title-text'>
                All Products
              </h1>
              <div className='product-list-all'>
                <Container className='product-list-all-container'>
                  <Row>
                    {
                      products ? (
                        products.map((product, index) => (
                          <Col md={2} xs={4} key={index} className='product-list-all-row'>
                            <div className='product-list-card product-list-card-all'>
                              <ProductCard product={product} section={'product'} />
                            </div>
                          </Col>
                        ))
                      ) : (
                        <>
                        </>
                      )
                    }
                  </Row>
                </Container>
              </div>
            </div>
          </div>
          <Footer />
        </>
      );
}

export default Home;