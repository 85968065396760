import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import WebNavbar from '../../components/navbar'
import Footer from '../../components/footer'

import { Helmet } from "react-helmet";

import Banner from '../../assets/banner-about-us.png'

import value1 from './../../assets/about-us-value-1.svg'
import value2 from './../../assets/about-us-value-2.svg'
import value3 from './../../assets/about-us-value-3.svg'

import textImg from './../../assets/about-us-text-img.png'

import award1 from './../../assets/about-us-no1-brand-award.svg'
import award2 from './../../assets/about-us-top-brand-award.svg'

import awardpic from './../../assets/about-us-award-pic.png'

const Home = () => {
    const [showRegister, setShowRegister] = useState(false);


    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - About Us</title>
            <link rel="canonical" href="https://bioaqua.co.id/about" />
            <meta name="description" content="Learn more about us and our commitment to providing high-quality skincare products at bioaqua Indonesia. Discover our story and our dedication to customer satisfaction."/>
          </Helmet>
          <WebNavbar showRegister={showRegister} setShowRegister={setShowRegister} />
          <div className='about-us'>
            <img className='about-us-banner' src={Banner} />
            <div className='about-us-value'>
                <Container>
                    <Row>
                        <Col md={4}>
                                <img className='home-menu-button-link-img' src={value1} />
                        </Col>
                        <Col md={4}>
                                <img className='home-menu-button-link-img' src={value2} />
                        </Col>
                        <Col md={4}>
                            <img className='home-menu-button-link-img' src={value3} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='about-us-text'>
                <img className='about-us-text-img' src={textImg} />
                <Container>
                    <h1 className='about-us-text-title'>Inspired By You!</h1>
                    <img className='about-us-text-img-mobile' src={textImg} />
                    <p>We believe in fast innovation to bring our commitment to always making Cost-Efficient beauty with great value.</p>
                    <p>From Head-Toe, A-Z BIOAQUA provides a lifestyle for your beauty and personal care.</p>
                    <p>BIOAQUA products under PT. Permata Indo Kav is registered in BPOM, GMPC, Halal, and ISO.</p>
                    <p>BIOAQUA's products are under own manufacturer with International Global Standard product research & development, and production.</p>
                </Container>
            </div>
            <div className='about-us-award'>
                <Container>
                    <img className='about-us-award-pic-img' src={awardpic} />
                    <Row>
                        <Col>
                        </Col>
                        <Col md={8} xs={8} className='about-us-award-section'>
                            <img className='about-us-award-img' src={award1} />
                            <img className='about-us-award-img' src={award2} />
                        </Col>
                    </Row>
                </Container>
            </div>
          </div>
          <Footer />
        </>
      );
}

export default Home;