import React, { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom"
import WebNavbar from '../../components/navbar'
import BodyMessage from '../../components/body-message'
import Footer from '../../components/footer'

import axios from 'axios'

import { Helmet } from "react-helmet";

const Home = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [showRegister, setShowRegister] = useState(false);

    useEffect(() => {
      let action = searchParams.get("action");
      if(action=="resend") {
        let token = sessionStorage.getItem('token');
        axios.get('https://api.bioaqua.co.id/api/auth/verification/resend', {
          headers: {
            Authorization: token
          }
        })
        .then(response => {
          window.location = '/registration/confirmation';
          return
        })
        .catch(errors => {
            console.log(errors)
        })
      }
    }, [])

    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Registration Confirmation</title>
            <link rel="canonical" href="https://bioaqua.co.id/registration/confirmation" />
            <meta name="description" content="Thank you for registering with Bioaqua! confirm your registration! Find out more about our products and services on our website."/>
          </Helmet>
          <WebNavbar showRegister={showRegister} setShowRegister={setShowRegister} />
          <BodyMessage title={"VERIFY YOUR EMAIL ADDRESS"} body={"A verification link has been sent to your email account. Go check your email to confirm your registration and start your BAesties Journey!"} metode={'email'} metodelink={'/registration/confirmation?action=resend'} />
          <Footer />
        </>
      );
}

export default Home;