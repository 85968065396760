import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';

import InstagramEmbed from 'react-instagram-embed';

import axios from 'axios';

import verifiedLogo from './../../assets/home-social-verified.png'

const HomeSocial = () => {
    const [igData, setIgData] = useState([]);
    const [igDetailLink, setIgDetailLink] = useState('');

    const handleClickIgDetail = (e) => {
        let permalink = e.currentTarget.getAttribute('data-link');
        setIgDetailLink(permalink);
        setShowIgDetail(true);
    }

    const [showIgDetail, setShowIgDetail] = useState(false);
    const handleCloseIgDetail = () => setShowIgDetail(false);

    const [tiktokData, setTiktokData] = useState([]);
    const [tiktokDetail, setTiktokDetail] = useState('');

    useEffect(() => {
        axios.get("https://api.bioaqua.co.id/api/social-media/content?source=https://bioaqua.co.id&media=ig")
        .then(response => {
            setIgData(response.data.data.slice(0, 6))
            return
        })
        .catch(errors => {
            console.log(errors)
            return
        })

        axios.get("https://api.bioaqua.co.id/api/social-media/content?source=https://bioaqua.co.id&media=tiktok&total=6")
        .then(response => {
            setTiktokData(response.data.data)
            return
        })
        .catch(errors => {
            console.log(errors)
            return
        })
    }, [])

    const handleClickTiktokDetail = (e) => {
        let permalink = e.currentTarget.getAttribute('data-id');
        setTiktokDetail(permalink);
        setShowTiktokDetail(true);
    }

    const [showTiktokDetail, setShowTiktokDetail] = useState(false);
    const handleCloseTiktokDetail = () => setShowTiktokDetail(false);

    return (
        <>
            <div id='social' className='home-social'>
                <Row>
                    {
                        igData.length > 0 ? (
                            <Col md={12} xs={12}>
                                <div className='home-social-instagram'>
                                    <Container className='home-social-instagram-container'>
                                            <h1 className='home-social-title'>
                                                Instagram
                                            </h1>
                                            <div className='home-social-account'>
                                                <span className='home-social-account-name'>bioquabeautyofficial </span>
                                                <img className='home-social-account-verified' src={verifiedLogo} />
                                                <div className='home-social-account-follow'>
                                                    <a href='https://www.instagram.com/bioaquabeautyofficial/' target={'_blank'}>
                                                        <Button variant='danger' className='home-social-account-follow-btn'>
                                                            Follow
                                                        </Button>
                                                    </a>
                                                </div>
                                            </div>
                                            <Row>
                                            {igData.map((data, key) => (
                                                <Col md={4} xs={4} key={key}>
                                                    <div className='home-social-instagram-img-section' data-link={data.permalink} onClick={handleClickIgDetail}>
                                                        {data.media_type == "VIDEO" ? (
                                                                <img className='home-social-instagram-img' src={data.thumbnail_url} />
                                                            ): (
                                                                <img className='home-social-instagram-img' src={data.media_url} />
                                                            )
                                                        }
                                                        <div className='home-social-instagram-caption' dangerouslySetInnerHTML={{__html: data.caption}} />
                                                    </div>
                                                </Col>
                                            ))
                                            }
                                            </Row>
                                    </Container>
                                </div>
                            </Col>
                        ) : (
                            <></>
                        )
                    }
                    {
                        tiktokData.length <= 0 ? (
                            <></>
                        ) : (
                            <Col md={12} xs={12}>
                                <div className='home-social-tiktok'>
                                    <Container className='home-social-instagram-container'>
                                        <div className='home-social-tiktok-content'>
                                            <h1 className='home-social-title'>
                                                TikTok
                                            </h1>
                                            <div className='home-social-account'>
                                                <span className='home-social-account-name'>bioaquaofficialstore </span>
                                                <img className='home-social-account-verified' src={verifiedLogo} />
                                                <div className='home-social-account-follow'>
                                                    <a href='https://www.tiktok.com/@bioaquaofficialstore' target={'_blank'}>
                                                        <Button variant='danger' className='home-social-account-follow-btn'>
                                                            Follow
                                                        </Button>
                                                    </a>
                                                </div>
                                            </div>
                                            <Row>
                                            {tiktokData.map((data, key) => (
                                                <Col md={4} xs={4} key={key}>
                                                    <div className='home-social-instagram-img-section' data-id={data.id} onClick={handleClickTiktokDetail}>
                                                        <img className='home-social-instagram-img' src={data.cover_image_url} />
                                                        <div className='home-social-instagram-caption' dangerouslySetInnerHTML={{__html: data.video_description}} />
                                                    </div>
                                                </Col>
                                            ))
                                            }
                                            </Row>
                                        </div>
                                    </Container>
                                </div>
                            </Col>
                        )
                    }
                    <Container>
                        <div className='home-social-border-bottom' />
                    </Container>
                </Row>
            </div>
            <Modal show={showIgDetail} onHide={handleCloseIgDetail}>
                <Modal.Header closeButton />
                <InstagramEmbed
                    url={igDetailLink}
                    clientAccessToken={`1632446757225504|yW9nGUGWzH8Z0v8he_31Xxb_CDc`}
                    maxWidth={'100%'}
                    hideCaption={false}
                    containerTagName='div'
                    protocol=''
                    injectScript
                    onLoading={() => {}}
                    onSuccess={() => {}}
                    onAfterRender={() => {}}
                    onFailure={() => {}}
                  />
            </Modal>

            <Modal show={showTiktokDetail} onHide={handleCloseTiktokDetail}>
                <Modal.Header closeButton />
                <iframe
                  className='tiktok-detail-iframe'
                  src={"https://www.tiktok.com/embed/"+tiktokDetail}
                  allow="encrypted-media;"
                  frameborder="0"
                  allowtransparency="true"
                ></iframe>
            </Modal>
        </>
    )
}

export default HomeSocial;