import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import {
  Home,
  Products,
  ProductsDetail,
  ProductsNew,
  ProductsBest,
  Career,
  ContactUs,
  Store,
  Questioner,
  RegistrationConfirmation,
  RegistrationConfirmationVerify,
  ForgotPassword,
  ForgotPasswordConfirmation,
  ForgotPasswordReset,
  ForgotPasswordSuccess,
  DashboardTicket,
  ArticleDetail,
  ArticleBrowse,
  ArticleHome,
  BioSquad,
  AboutUs
} from "./pages";
import './fonts.css';

import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <GoogleOAuthProvider clientId="771411009979-p7n56a4ora2t7p683e17p77a411vs279.apps.googleusercontent.com">
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:name" element={<ProductsDetail />} />
            <Route path="/products/new" element={<ProductsNew />} />
            <Route path="/products/best" element={<ProductsBest />} />
            <Route path="/career" element={<Career />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/store" element={<Store />} />
            <Route path="/questioner" element={<Questioner />} />
            <Route path="/registration/confirmation" element={<RegistrationConfirmation />} />
            <Route path="/registration/confirmation/verify" element={<RegistrationConfirmationVerify />} />
            <Route path="/password/forgot" element={<ForgotPassword />} />
            <Route path="/password/forgot/confirmation" element={<ForgotPasswordConfirmation />} />
            <Route path="/password/forgot/reset" element={<ForgotPasswordReset />} />
            <Route path="/password/forgot/success" element={<ForgotPasswordSuccess />} />
            <Route path="/member/ticket" element={<DashboardTicket />} />
            <Route path="/articles" element={<ArticleHome />} />
            <Route path="/articles/:name" element={<ArticleDetail />} />
            <Route path="/articles/browse" element={<ArticleBrowse />} />
            <Route path="/biosquad" element={<BioSquad />} />
            <Route path="/about" element={<AboutUs />} />
          </Routes>
        </Router>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
