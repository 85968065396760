import { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import bastiesImg from '../../assets/home-basties-img.png';

const HomeSocial = ({setShowRegister, questions, setQuestions}) => {
    const[questioner, setQuestioner] = useState([{}]);

    useEffect(() => {
        fetch(
            `https://api.bioaqua.co.id/api/questions`,
            {
              method: "GET"
            }
        )
        .then(res => res.json())
        .then(response => {
            setQuestioner(response);
        })
        .catch(error => console.log(error));
    }, [])

    const handleRegistration = () => {
        setShowRegister(true);
    }

    const handleSelectQuestions = (e) => {
        let index = e.target.getAttribute('data-index')
        let questionsForm = questions;
        if(questionsForm[index] != "") {
            questionsForm[index] = {
                question: e.target.name,
                answer: e.target.value
            } 
        } else {
            let newQuestionsForm = {
                question: e.target.name,
                answer: e.target.value
            }
            questionsForm.push(newQuestionsForm);
        }
        setQuestions(questionsForm);
    }

    return (
        <>
            <div id='basties' className='home-basties'>
                <Container>
                    <Row className='home-basties-content'>
                        <Col>
                            <div className='home-basties-title'>
                                <p className='home-basties-title-text'>
                                    Hai BAesties ! Yuk bergabung menjadi BIOAQUA Squad!
                                </p>
                            </div>
                            <div className='home-basties-question'>
                                <Form>
                                    {
                                        questioner &&
                                        questioner.map((question, key) => (
                                            <Form.Group key={key} className="home-basties-form-group mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>{question.questions}</Form.Label>
                                                <Form.Select data-index={key} name={question.id} defaultValue='' className='home-basties-form-select' aria-label="Default select example" onChange={handleSelectQuestions}>
                                                    <option disabled value=''>Pilih Jawaban</option>
                                                    {
                                                        question.answers &&
                                                        question.answers.map((answer, aKey) => (
                                                            <option key={aKey} value={answer.id}>{answer.answer}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        ))
                                    }
                                    <Button className='home-basties-form-button' variant='primary' onClick={handleRegistration}>
                                        Bergabung Sekarang
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                        <Col className='home-basties-img-section'>
                            <img src={bastiesImg} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default HomeSocial;