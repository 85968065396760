import React, {useState} from 'react'
import WebNavbar from '../../components/navbar'
import Footer from '../../components/footer'
import CareerBrowse from '../../components/career-browse'

import { Helmet } from "react-helmet";

const Home = () => {
    const [showRegister, setShowRegister] = useState(false);

    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Career</title>
            <link rel="canonical" href="https://bioaqua.co.id/career" />
            <meta name="description" content="Explore career opportunities at Bioaqua, a leading company in the beauty industry. We offer a variety of positions and value diversity, growth, and creativity. Check out our available job openings and join our dynamic team today."/>
          </Helmet>
          <WebNavbar showRegister={showRegister} setShowRegister={setShowRegister} />
          <CareerBrowse />
          <Footer />
        </>
      );
}

export default Home;