import loadable from '@loadable/component'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Container, Carousel, Button } from 'react-bootstrap'

import { Helmet } from "react-helmet";

import TiktokIcon from './../../assets/buy-icon-tiktok.svg'
import TokopediaIcon from './../../assets/buy-icon-tokopedia.svg'
import ShopeeIcon from './../../assets/buy-icon-shopee.svg'

const Navbar = loadable(() => import('../../components/navbar'))
const Footer = loadable(() => import('../../components/footer'))

const Home = () => {
  const [data, setData] = useState({});
  const { name } = useParams();
  const [showRegister, setShowRegister] = useState(false);
  const numberWithCommas = (x) => {
        if(x != null){
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return '';
    }

    const handleShowLink = (link) => {
        window.location = link
    }

  useEffect(() => {
    console.log(name);
    fetch(
        `https://api.bioaqua.co.id/api/products/withquery?key=name&value=`+encodeURIComponent(name),
        {
          method: "GET"
        }
        )
        .then(res => res.json())
        .then(response => {
          setData(response);
        })
        .catch(error => console.log(error));
    }, [])
    

  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Product Details</title>
            <link rel="canonical" href={"https://bioaqua.co.id/products/"+data.name} />
            <meta name="description" content="Bioaqua offers a variety of beauty and skincare products to help you achieve a clear, healthy-looking complexion. Learn more about their range of products, their ingredients, and their benefits."/>
          </Helmet>
        <Navbar showRegister={showRegister} setShowRegister={setShowRegister} />
        <div className='product-details'>
                <div className='product-details-name-section'>
                    <center>
                        <h5 className='product-details-display-name'>{data.display_name}</h5>
                        <h4 className='product-details-name'>{data.name}</h4>
                    </center>
                </div>
                <div className='product-details-image-section'>
                    <center>
                        <Carousel className='product-details-image-carousel'>
                            {
                                data.images &&
                                    data.images.map((image, key) => (
                                        <Carousel.Item key={key}>
                                            <img
                                            className="product-details-image"
                                            src={"https://api.bioaqua.co.id/products/"+image}
                                            />
                                        </Carousel.Item>
                                    ))
                            }
                        </Carousel>
                    </center>
                </div>
                <div className='product-details-text'>
                    <Container>
                        <div className='product-details-desc-section'>
                            <p className='product-details-tagline'>{data.tagline}</p>
                            <div className='product-details-desc' dangerouslySetInnerHTML={{__html: data.description}} />
                        </div>
                        <div className='product-details-price-section'>
                            <p className='product-details-price'>Rp {numberWithCommas(data.price)}</p>
                            <span className='product-details-price-after'>Rp {numberWithCommas(data.priceAfterDisc)}</span>
                        </div>
                        <div className='product-detail-button-section'>
                            <p className='product-detail-button-title'>Buy Now At:</p>
                            <Button onClick={() => handleShowLink(data.link_shopee)} className='product-details-btn'>
                                <img src={ShopeeIcon} />
                            </Button>
                            <Button onClick={() => handleShowLink(data.link_tiktok)} className='product-details-btn product-details-btn-small product-details-btn-tiktok'>
                                <img src={TiktokIcon} />
                            </Button>
                            <Button onClick={() => handleShowLink(data.link_tokopedia)} className='product-details-btn product-details-btn-small'>
                                <img src={TokopediaIcon} />
                            </Button>
                        </div>
                    </Container>
                </div>
        </div>
        <Footer />
    </>
  )
}

export default Home;