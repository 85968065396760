import React, {useEffect, useState} from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'

const Store = () => {
    const [stores, setStores] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([])
    const [selectedName, setSelectedName] = useState('')
    const [selectedStreet, setSelectedStreet] = useState('')

    const [displayedStores, setDisplayedStore] = useState([]);

    useEffect(() => {
        fetch(
            `https://api.bioaqua.co.id/api/store`,
            {
              method: "GET"
            }
        )
        .then(res => res.json())
        .then(response => {
            setStores(response)
            setDisplayedStore(response)

            let locations = []
            locations.push(parseFloat(response[0].latitude))
            locations.push(parseFloat(response[0].longitude))
            setSelectedLocations(locations)
            setSelectedName(response[0].name)
            setSelectedStreet(response[0].address)
        })
        .catch(error => console.log(error));
    }, [])

    const handleLocations = (store) => {
        let locations = []
        locations.push(parseFloat(store.latitude))
        locations.push(parseFloat(store.longitude))
        setSelectedLocations(locations)
        setSelectedName(store.name)
        setSelectedStreet(store.address)
    }

    const RecenterAutomatically = ({positions}) => {
        const map = useMap();
         useEffect(() => {
           map.setView(positions);
         }, positions);
         return null;
    }

    const handleSearchLocations = (e) => {
        let store = stores
        if(e.target.value == ""){
            setDisplayedStore(store)
            let locations = []
            locations.push(parseFloat(store[0].latitude))
            locations.push(parseFloat(store[0].longitude))
            setSelectedLocations(locations)
            setSelectedName(store[0].name)
            setSelectedStreet(store[0].address)
            return
        }
        
        let newStores = []
        for(var i = 0; i < store.length; i++) {
            if (store[i].address.toLowerCase().includes(e.target.value.toLowerCase())) {
                newStores.push(store[i])
            }
        }

        setDisplayedStore(newStores);
        if(newStores.length > 0) {
            let locations = []
            locations.push(parseFloat(newStores[0].latitude))
            locations.push(parseFloat(newStores[0].longitude))
            setSelectedLocations(locations)
            setSelectedName(newStores[0].name)
            setSelectedStreet(newStores[0].address)
        }
    }

    return (
        <>
            <div className='store'>
                <Container>
                    <div className='store-title'>
                        <p className='store-title-text'>
                            Locate Our Store
                        </p>
                    </div>
                    <div className='store-content'>
                        <div className='store-content-search'>
                            <h3>Find Nearby Store</h3>
                            <Form.Control type="text" placeholder="Add Location" className='store-content-search-input' onChange={handleSearchLocations} />
                        </div>
                        <div className='store-content-location'>
                            <Row>
                                {
                                    displayedStores.length > 0 ? (
                                        <>
                                        <Col md={4}>
                                            <div className='store-content-location-list'>
                                                {
                                                    displayedStores.map((store, index) => (
                                                        <div className='store-content-location-list-store' key={index} onClick={() => handleLocations(store)}>
                                                            <p>{store.name}</p>
                                                            <p>{store.address}</p>
                                                            <a href={'https://www.google.com/maps/dir/?api=1&destination='+store.latitude+','+store.longitude}>
                                                                <Button className='store-content-location-direction-btn' variant='primary'>
                                                                    View Directions
                                                                </Button>
                                                            </a>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Col>
                                        <Col md={8}>
                                            {
                                                selectedLocations.length > 0 ? (
                                                    <MapContainer center={selectedLocations} zoom={16} scrollWheelZoom={false}>
                                                            <TileLayer
                                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                            />
                                                        <Marker position={selectedLocations}>
                                                            <Popup>
                                                                {selectedName} <br /> {selectedStreet}
                                                            </Popup>
                                                        </Marker>
                                                        <RecenterAutomatically positions={selectedLocations} />
                                                    </MapContainer>
                                                ) : (
                                                    <>
                                                    </>
                                                )
                                            }
                                        </Col>
                                        </>
                                    ): (
                                        <>
                                        <center><p>Locations Not Found</p></center>
                                        </>
                                    )
                                }
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Store;