import React from 'react';

import logo  from '../../assets/logo-blue.png'

const Content = () => {

    return (
        <>
            <div className='loading'><img className='loading-screen-image' src={logo} /></div>
        </>
    );
};

export default Content;