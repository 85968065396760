import React, {useState} from 'react'
import WebNavbar from '../../components/navbar'
import Footer from '../../components/footer'
import Contact from '../../components/contact'

import { Helmet } from "react-helmet";

const Home = () => {
    const [showRegister, setShowRegister] = useState(false);

    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>BioAqua - Contact Us</title>
            <link rel="canonical" href="https://bioaqua.co.id/contact" />
            <meta name="description" content="Contact Bioaqua with any questions or concerns. Our customer service representatives are available to assist you."/>
          </Helmet>
          <WebNavbar showRegister={showRegister} setShowRegister={setShowRegister} />
          <Contact />
          <Footer />
        </>
      );
}

export default Home;